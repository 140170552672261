import styled from "@emotion/styled"
import mq from "@styles/mq"
import { GridColumn, Segment, Icon, Grid } from "semantic-ui-react"
import theme from "@styles/theme"
import { H2 } from "@styles/Global.styles"
import { KpButton } from "@elements/index"

export const OrderConfirmationLink = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: black;
`

export const OrderConfirmationLinkContainer = styled.div`
  ${mq({
    marginRight: ["0", "0", "28px"],
    display: ["block", "block", "block", "inline-block"],
  })}
`

export const OrderConfirmationColumn = styled(GridColumn)`
  padding-left: 0 !important;
  padding-right: 0 !important;

  > h6 {
    margin-top: 0 !important;
  }
`
export const GoogleReviewButton = styled(KpButton)`
  margin-top: 5px !important;
  margin-bottom: 20px !important;
`
export const OrderConfirmationContactInfoContainer = styled(
  OrderConfirmationColumn
)`
  margin-top: 10px !important;

  > p {
    ${mq({
      marginBottom: ["0", "0", "15px"],
      marginTop: ["25px", "0", "0"],
    })}
  }
`

export const OrderSummaryDeliveryProgressContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  ${mq({
    flexDirection: ["row-reverse", "column", "column"],
    justifyContent: ["flex-end", "space-between", "space-between"],
    padding: ["30px 30px 15px 30px", "60px", "60px"],
  })}
`

export const OrderSummaryDeliveryProgressStepContainer = styled.div`
  position: relative;
  text-align: center;
`

export const OrderSummaryDeliveryProgressStep = styled.div`
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 40px;
  background-color: #b7b7b7;
  color: white;
  z-index: 2;

  ${mq({
    margin: ["22px 0", "0 46px", "0 46px"],
  })}

  > i {
    margin-top: 10px;
    margin-left: 5px;
  }

  &.complete {
    background-color: ${theme.brand.colors.green};
  }

  &.cancelled {
    background-color: ${theme.brand.colors.error};
  }
`

export const OrderSummaryDeliveryProgressConnector = styled.div`
  background-color: #b7b7b7;
  flex: 1;
  z-index: 1;

  ${mq({
    margin: ["-50px 0", "0 -50px", "0 -50px"],
    height: ["auto", "10px", "10px"],
    width: ["10px", "auto", "auto"],
  })}

  &.complete {
    background-color: ${theme.brand.colors.green};
  }

  &.cancelled {
    background-color: ${theme.brand.colors.error};
  }
`

export const OrderSummaryDeliveryProgressRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  align-items: center;

  ${mq({
    flexDirection: ["column", "row", "row"],
  })}

  > h6 {
    ${mq({
      textAlign: ["left", "center", "center"],
      width: ["150px", "132px", "132px"],
      margin: ["20px 0 !important", "0 !important", "0 !important"],
      paddingLeft: ["30px", "0", "0"],
    })}
  }
`

export const OrderConfirmationContentHeading = styled(H2)`
  margin-bottom: 35px !important;
`

export const OrderSummaryDeliveryProgressSegment = styled(Segment)`
  padding: 0 !important;
  width: 100%;
`

export const LoadingIcon = styled(Icon)`
  margin-left: 10px !important;
`
export const DealerManageContentArea = styled(Grid.Column)`
  padding-left: 30px !important;
  padding-top:20px !important;
  padding-right: 0 !important;
`
export const OrderConfirmationContactInfoContainerMobile = styled(
  OrderConfirmationColumn
)`
  margin-top: 10px !important;

  > p {
    ${mq({
      marginBottom: ["0", "0", "15px"],
      marginTop: ["25px", "0", "0"],
    })}
  }
  display: none !important;
  @media (max-width: 1024px) {
      display : inline-block !important; 
  }
`